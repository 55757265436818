<template>
  <div class="w-full reports-text">
    <card-form class="w-full mb-2" style="padding: 30px 0px;">
      <div class="text-reports-title flex justify-center">
        <a-icon type="bar-chart" class="icon-v2"/>
        <div>
          Отчеты и статистика
        </div>
      </div>
      <div class=" flex justify-center">
        <a-collapse accordion class="mb-2 w-1/2">
          <a-collapse-panel key="1" :header="$t('filters')">
            <FormSelect :formItems="filters" ref="refFormCreateRequest" :cols="1" class="mb-2" updated_event_bus="updated_reports"/>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </card-form>

    <div class="w-full flex">
      <div class="w-1/3 p-2">
        <chart-doughnut></chart-doughnut>
      </div>
      <div class="w-1/3 p-2">
        <ChartPie/>
      </div>
      <div class="w-1/3 p-2">
        <ChartBar2/>
      </div>
    </div>
</div>
</template>

<script>
import CardForm from "@/pages/MainPage/DashboardCard/CardForm";
import ChartDoughnut from "./ChartDoughnut";
import ChartPie from "./ChartPie";
import ChartBar2 from "./ChartBar2";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import {mapActions} from "vuex";
import RequestFilterMixin from "@/pages/AddClient/RequestMixins/RequestFilterMixin";
export default {
  name: "Reports",
  components: {CardForm, ChartDoughnut, FormSelect, ChartPie, ChartBar2,},
  mixins:[
    RequestFilterMixin
  ],
  data(){
    return{
      chartData: [
        { iconClass: 'icon-web', percentage: 15 },
        { iconClass: 'icon-telegram', percentage: 50 },
        { iconClass: 'icon-whatsapp', percentage: 25 },
        { iconClass: 'icon-phone', percentage: 10 },
      ],
    }
  },
  methods:{
    ...mapActions({

    })
  },
  async created() {
  }
}
</script>

<style lang="scss">
.reports-text{
  .text-reports-title {
    font-weight: 550;
    font-size: 34px;
  }
  .icon-v2 {
    font-size: 50px;
    margin: -10px 0px 20px 20px;
    bottom: 0;
    position: relative;
    z-index: 1;
  }
}
.hex {
  height: 115px;
  width: 118px;
  background: #e4fbff;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  text-align-last: center;
}

</style>

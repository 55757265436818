<template>
  <a-descriptions  bordered>
    <a-descriptions-item  v-for="(item, index) in show_data" :key="index" :span="3">
      <template slot="label">
        <a-badge :color="background_color[index]"/>
        {{item.label}}
      </template>
      {{item.count}}
    </a-descriptions-item>
    <a-descriptions-item :span="3">
      <template slot="label">
        <a-badge status="default" />
        {{$t('total')}}
      </template>
      {{show_data.reduce((acc, item) => acc + item.count, 0)}}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
export default {
  name: "DescriptionsComp",
  props: {
    show_data: Array,
    background_color: Array
  }
}
</script>

<style scoped>

</style>

<script>
import {mapActions} from "vuex";
import {EventBus} from "@/configs/eventBus";

export default {
  name: "ChartMixin",
  props:{
    updated_event_bus:{
      type:String,
      default:'updated_reports'
    },
  },
  data(){
    return{
      background_color:[
          '#F5222D',
      '#B37FEB',
      '#1890FF',
      '#52C41A',
      '#13C2C2',
      '#2F54EB',
      '#722ED1',
      '#EB2F96',
      '#FA8C16',
        '#FADB14',
        '#FA541C',
      '#FAAD14',
      '#A0D911',
      '#52C41A',
      '#13C2C2',
      '#1890FF',
      '#722ED1',
      '#EB2F96',
      '#FA8C16',
      '#FA541C',
      '#FAAD14',
      '#A0D911',
      '#52C41A',
      '#13C2C2',
      '#1890FF',
      '#722ED1',
      '#EB2F96',
      '#FA8C16',
      '#FA541C',
      '#FAAD14',
      '#A0D911',
      '#52C41A',
      '#13C2C2',
      '#1890FF',
      '#722ED1',
      '#EB2F96',
      '#FA8C16',
  ]
    }
  },
  created(){
    console.log('EventBus.$on(this.updated_event_bus, this.get_statistics);')
    EventBus.$on(this.updated_event_bus, this.get_statistics);
    console.log('EventBus.$on(this.updated_event_bus, this.get_statistics);')

  },
  beforeDestroy(){
    EventBus.$off(this.updated_event_bus);
  },
  methods:{
    ...mapActions({
      STATISTICS_REPORTS_PAGE:'client/STATISTICS_REPORTS_PAGE',
    }),

    async get_statistics() {
      console.log('get_statistics');
      await this.fetch_all();
      let labels = [];
      let data = [];
      let clone_show_data = [];

      const promises = this.get_all.map(async (item, index) => {
        const res = await this.STATISTICS_REPORTS_PAGE({...this.$route.query, [this.params_name]: item.uid, });
        return { label: item.name, count: res.count, index: index };
      });

      const results = await Promise.all(promises);

      // Сортировка по индексу, чтобы гарантировать правильный порядок
      results.sort((a, b) => a.index - b.index);

      // Заполнение массивов labels, data и clone_show_data в правильном порядке
      results.forEach(result => {
        labels.push(result.label);
        data.push(result.count);
        clone_show_data.push({ label: result.label, count: result.count });
      });

      this.show_data = clone_show_data;
      console.log('data', data);
      console.log('show_data', this.show_data);

      // Обновление данных графика
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = data;
      this.chart.update();
    }
  }

}
</script>

<style scoped>

</style>

<template>
  <div class="chart-container">
		<!-- Bar chart 2 -->
    <div class="flex">
      <div style="width: min-content">
        <canvas ref="chart" class="chart-bar-2" :style="{'height': height + 'px'}"></canvas>
      </div>
    </div>
    <descriptions-comp :background_color="background_color" :show_data="show_data"/>

    <!-- / Bar chart 2 -->
	</div>
</template>

<script>
	import { Chart, registerables } from 'chart.js';
  import {mapActions, mapGetters} from "vuex";
	Chart.register(...registerables);
  import DescriptionsComp from "@/pages/Reports/Charts/DescriptionsComp";
  import ChartMixin from "@/pages/Reports/ChartMixin";

	export default ({
    components: {DescriptionsComp},
    mixins:[
      ChartMixin
    ],
		props: [
		],
		data(){
			return {
				height: 300,
        show_data: [],
        params_name:'organization_request'
      } ;
		},
		mounted () {
    		let ctx = this.$refs.chart.getContext("2d");

			this.chart = new Chart(ctx, {
				type: "bar",
				data: {
					labels: [],
					datasets: [{
						label: "Заявки",
						weight: 5,
						borderWidth: 0,
						borderRadius: 4,
            backgroundColor: this.background_color,
						data: [15, 20, 12, 60, 20, 15],
						fill: false,
						maxBarThickness: 35
					}],
				},
     			options: {
					layout: {
						padding: {
							top: 30,
							right: 15,
							left: 10,
							bottom: 5,
						},
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
					},
					tooltips: {
						enabled: true,
						mode: "index",
						intersect: false,
					},
					scales: {
						y: {
              suggestedMax: 10,
              grid: {
								drawBorder: false,
								display: true,
								drawOnChartArea: true,
								drawTicks: false,
								borderDash: [5, 5]
							},
							ticks: {
								display: true,
								padding: 10,
								color: '#b2b9bf',
								font: {
									size: 11,
									family: "Open Sans",
									style: 'normal',
									lineHeight: 2
								},
							},
						},
						x: {
							grid: {
								drawBorder: false,
								display: true,
								drawOnChartArea: true,
								drawTicks: true,
								borderDash: [5, 5]
							},
							ticks: {
								display: true,
								color: '#b2b9bf',
								padding: 10,
								font: {
									size: 11,
									family: "Open Sans",
									style: 'normal',
									lineHeight: 2
								},
							},
						},
					},
				}
			});
      this.get_statistics()
		},
    computed:{
      ...mapGetters({
        get_all:'client/GET_ORGANIZATION_ALL',
      })
    },
    methods:{
      ...mapActions({
        fetch_all:'client/ORGANIZATION_ALL',
      }),
    },
		beforeDestroy: function () {
			this.chart.destroy() ;
		},
	})

</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="chart-container">
    <div class="flex">
      <div style="width: min-content">
        <canvas ref="chart" class="chart-doughnut" :style="{'height': height + 'px'}"></canvas>
      </div>
    </div>
    <descriptions-comp :background_color="background_color" :show_data="show_data"/>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { mapActions, mapGetters } from "vuex";
import ChartMixin from "@/pages/Reports/ChartMixin";
import DescriptionsComp from "@/pages/Reports/Charts/DescriptionsComp";
Chart.register(...registerables);

export default {
  components: {DescriptionsComp},
  props: [],
  data() {
    return {
      height: 300,
      show_data: [],
      params_name:'region_fk'
    };
  },
  mixins:[
    ChartMixin
  ],
  mounted() {
    let ctx = this.$refs.chart.getContext("2d");

    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [{
          label: "Заявки",
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: this.background_color,
          data: [],
          fill: false
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            }
          },
        },
      },
    });
    this.get_statistics();
  },
  beforeDestroy: function () {
    this.chart.destroy();
  },
  computed: {
    ...mapGetters({
      get_all: 'client/GET_REGION_ALL',
    })
  },
  methods: {
    ...mapActions({
      fetch_all: 'client/REGION_ALL',
    }),
  }
};
</script>

<template>
	<div>

		<!-- Pie chart -->
    <div class="flex">
<!--      <ul class="data-list">-->
<!--        <li v-for="(item, index) in show_data" :key="index">-->
<!--          {{ item.label }}: {{ item.count }}-->
<!--        </li>-->
<!--      </ul>-->
      <div style="width: min-content">
        <canvas ref="chart" class="chart-doughnut" :style="{'height': height + 'px'}"></canvas>
      </div>
    </div>
    <descriptions-comp :background_color="background_color" :show_data="show_data"/>
		<!-- / Pie chart -->
	</div>
</template>

<script>
	import { Chart, registerables } from 'chart.js';
  import {mapActions, mapGetters} from "vuex";
	Chart.register(...registerables);

  import DescriptionsComp from "@/pages/Reports/Charts/DescriptionsComp";
  import ChartMixin from "@/pages/Reports/ChartMixin";

	export default ({
    components: {DescriptionsComp},
		props: [
		],
		data(){
			return {
				height: 300,
        show_data: [],
        params_name:'source_request'
      } ;
		},
    async created() {

    },
    mounted () {
    		let ctx = this.$refs.chart.getContext("2d");

			this.chart = new Chart(ctx, {
				type: "pie",
				data: {
					labels: ['Facebook', 'Direct', 'Organic', 'Referral'],
					datasets: [{
						label: "Заявки",
						weight: 9,
						cutout: 0,
						tension: 0.9,
						pointRadius: 2,
						borderWidth: 2,
						backgroundColor: this.background_color,
						data: [15, 20, 12, 60],
						fill: false
					}],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
					legend: {
						display: false,
					}
					},
					interaction: {
					intersect: false,
					mode: 'index',
					},
					scales: {
					y: {
						grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
						},
						ticks: {
						display: false
						}
					},
					x: {
						grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
						},
						ticks: {
						display: false,
						}
					},
					},
				},
			});
      this.get_statistics()
		},
		// Right before the component is destroyed,
		// also destroy the chart.
		beforeDestroy: function () {
			this.chart.destroy() ;
		},
    mixins:[
      ChartMixin
    ],
    computed:{
      ...mapGetters({
        get_all:'client/GET_SOURCE_ALL',
      })
    },
    methods:{
      ...mapActions({
        fetch_all:'client/SOURCE_ALL',
      }),
    }
	})

</script>

<style lang="scss" scoped>
</style>

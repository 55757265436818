<template>
  <div class="card-from-dashboard " :style="{ backgroundColor: bgColor, color: color }">
    <div class="content" style="width: 100%; height: 100%;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardForm",
  props:{
    bgColor: {
      type: String,
      default: '#f5f5f5'
    },
    color: {
      type: String,
      default: '#438655'
    }
  }
}
</script>

<style lang="scss">
  .card-from-dashboard {
    line-height: normal;
    position: relative;
    border: 4px solid #0bae37;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .content{
      position: relative;
      z-index: 1;
    }
  }
  .card-from-dashboard::before {
    content: "";
    position: absolute;
    top: 0;
    background-position: center 50px;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../../assets/images/CRMLogo.png'); // Add the path to your image here
    background-size: cover; // This makes sure the image covers the entire card
    filter: blur(5px); // This adds a blur effect to the background image
    z-index: 0;
  }

</style>
